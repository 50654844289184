<template>
  <div class="stepContent commonClass">
    <h3 class="titHead">建模接种</h3>
    <div class="detail">
      <div class="flex  backReturn">
        <h4 class="title">建模接种复核</h4>
        <div>
          <el-button size="small" plain class="return" @click="exportExcel">导出</el-button>
          <el-button v-if="user.userId != cageMap.modelVacc.reviewer" size="small" plain class="return"
                     @click="$emit('changePageGroup', true,2)">返回列表页
          </el-button>
        </div>
      </div>
      <table class="table specTable">
        <tr>
          <th colspan="3">方案编号</th>
          <td colspan="3">{{ activeInfo.number }}</td>
          <th colspan="2">动物品系</th>
          <td colspan="3">{{ activeInfo.strain }}</td>
        </tr>
        <tr style="height: 10px"></tr>
        <tr>
          <th>笼号</th>
          <th>笼架号</th>
          <th>耳号</th>
          <th>性别</th>
          <th colspan="3">接种部位</th>
          <th>是否接种成功</th>
          <th>备注</th>
        </tr>
        <template v-for="item in cageMap.cageMap">
          <tr v-for="(obj,index) in item">
            <td :rowspan="item.length" v-if="index==0">{{ obj.cageNo }}</td>
            <td :rowspan="item.length" v-if="index==0">{{ obj.frameNo }}</td>
            <td>{{ obj.overbit }}</td>
            <td>{{ obj.gender == 0 ? '♀' : '♂' }}</td>
            <td colspan="3">
              <template v-for="sus in obj.modelSusDetailList">细胞种类：{{ sus.cellType }} 接种部位：{{ sus.vaccSite }}<br/>
              </template>
            </td>
            <td>{{ obj.isFail == 0 ? '是' : '否' }}</td>
            <td>{{ obj.remark }}</td>
          </tr>
        </template>
        <tr>
          <td colspan="3">操作人</td>
          <td colspan="3">{{ cageMap.modelVacc.createName }}</td>
          <td colspan="2">日期</td>
          <td colspan="3">{{ cageMap.modelVacc.createTime | formatDay }}</td>
        </tr>
      </table>
      <!--      复核-->
      <template v-if="cageMap.modelVacc.state==1&&user.userId == cageMap.modelVacc.reviewer">
        <h4 class="title">悬液配置复核</h4>
        <el-form ref="vaccForm" size="small" :model="vaccForm" class="addForm" label-suffix=":"
                 label-width="90px">
          <el-form-item label="复核结果">
            <el-radio-group v-model="vaccForm.state" class="radioGroup">
              <el-radio :label="2">复核通过</el-radio>
              <el-radio :label="3">返回修改(复核意见必填)</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="复核意见" prop="reviewRemark"
                        :rules="validateType">
            <el-input type="textarea" rows="4" placeholder="请输入复核意见" v-model="vaccForm.reviewRemark"></el-input>
          </el-form-item>
          <div class="fromSave">
            <el-button type="primary" size="small" @click="checkSubmit('vaccForm')">复核完成</el-button>
          </div>
        </el-form>
      </template>
      <template v-else-if="cageMap.modelVacc.state==1&&user.userId != cageMap.modelVacc.reviewer"></template>
      <template v-else>
        <h4 class="title">悬液配置复核结果</h4>
        <div class="desc">
          <p>复核结果：{{ cageMap.modelVacc.state == 2 ? '复核通过' : cageMap.modelVacc.state == 3 ? '复核拒绝' : '' }}</p>
          <p>复核意见：{{ cageMap.modelVacc.reviewRemark }}</p>
          <span>复核人：{{ cageMap.modelVacc.reviewName }}</span>
          <span>复核日期：{{ cageMap.modelVacc.reviewTime | formatDay }}</span>
        </div>
      </template>
    </div>
  </div>

</template>

<script>
import FileSaver from "file-saver";
import moment from "moment";
import XLSX from "xlsx";
import {mapState} from "vuex";

export default {
  name: "vaccinationCheck",
  props: ['id'],
  data() {
    return {
      activeInfo: {},
      cageMap: {
        modelVacc: {}
      },
      vaccForm: {
        state: 2,
        reviewRemark: "",
      },
    }
  },
  mounted() {
    this.$get("/subject/" + this.id).then((res) => {
      if (res.status == 200) {
        this.activeInfo = res.data
      }
    })
    this.getData()
  },
  computed: {
    ...mapState({
      user: state => state.account.user
    }),
    validateType() {
      return this.vaccForm.state === 3
          ? [{required: true, message: '请输入复核意见', trigger: 'blur'}]
          : []
    },
  },
  methods: {
    getData() {
      this.$get('/model/vacc/' + this.id).then(res => {
        if (res.status == 200) {
          this.cageMap = res.data
        }
      })
    },
    // 导出
    exportExcel() {
      let name = moment(new Date()).format('YYYY-MM-DD') + '建模接种复核表';
      let wb = XLSX.utils.table_to_book(document.querySelector(".table"));
      let wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array"
      });
      try {
        //  name+'.xlsx'表示导出的excel表格名字
        FileSaver.saveAs(
            new Blob([wbout], {type: "application/octet-stream"}),
            name + ".xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      return wbout;
    },
    // 提交复核
    checkSubmit(checkForm) {
      this.$refs[checkForm].validate((valid) => {
        if (valid) {
          this.vaccForm.subId = this.id
          this.$put('/model/vacc/state', this.vaccForm).then(res => {
            if (res.status == 200) {
              this.$message.success('提交成功')
              this.$router.go(-1)
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";
</style>
